// スプレッドビューアのラッパ

<template lang="pug">
spread-viewer.spread-viewer-wrapper.absolute.w-full.h-full(
  :width='currentSpreadWidth',
  :height='currentSpreadHeight',
  :contents='contents',
  :spine='spine',
  :margin='margin',
  :backgroundColor='backgroundColor',
  :viewPort='viewPort',
  :isReadOnly='isPreview',
  :selectedContentId='selectedContentId',
  :scope='currentSpreadScope',
  :movableAreaRect='currentSpreadMovableAreaRect',
  @clickContent='clickContent',
  @clickBackground='clickBackground',
  @updateSelectedContent='updateSelectedContent',
  @removeSelectedContent='removeSelectedContent',
  @dragContentStart='$emit("dragContentStart")',
  @dragContentEnd='$emit("dragContentEnd")'
)
</template>

<script>
import get from 'lodash.get'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'SpreadViewerWrapper',

  props: {
    margin: {
      type: Number,
      default: 8
    },
    isPreview: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapGetters({
      currentSpread: 'editor/currentSpread',
      currentSpreadWidth: 'editor/currentSpreadWidth',
      currentSpreadHeight: 'editor/currentSpreadHeight',
      currentSpreadScope: 'editor/currentSpreadScope',
      currentSpreadMovableAreaRect: 'editor/currentSpreadMovableAreaRect',
      viewPortInEditor: 'editor/viewPortInEditor',
      viewPortInPreview: 'editor/viewPortInPreview',
      contents: 'editor/currentMergedContents',
      selectedContentId: 'editor/selectedContentId'
    }),
    spine () { return get(this.currentSpread, 'spine', 0) },
    backgroundColor () {
      // 塗りつぶしか
      if (get(this.currentSpread, 'background.type', 'fill') === 'fill') {
        return get(this.currentSpread, 'background.color', '#fff')
      } else {
        // 不明なら白を
        return '#fff'
      }
    },
    viewPort () {
      return this.isPreview ? this.viewPortInPreview : this.viewPortInEditor
    }
  },

  methods: {
    ...mapActions({
      selectContent: 'editor/selectContent',
      unselectContent: 'editor/unselectContent',
      updateContent: 'editor/updateContent',
      removeContent: 'editor/removeContent'
    }),
    // 特定のコンテントがクリックされた
    clickContent (content) {
      this.selectContent(content.id)
    },
    // 背景がクリックされた
    clickBackground () {
      this.unselectContent()
    },
    // 移動等でコンテントの情報がアップデートされた
    updateSelectedContent (params) {
      this.updateContent({
        contentId: this.selectedContentId,
        params
      })
    },
    // 選択中のコンテントを削除
    removeSelectedContent () {
      this.removeContent(this.selectedContentId)
    }
  }
}
</script>

<style lang="sass" scoped>

</style>
