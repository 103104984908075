// プレビューへはじめて遷移した際のメッセージ

<template lang="pug">
modal-base.introduction-for-preview(
  size='s',
  @clickClose='$closeModal(true)'
)

  .p-3

    h1.text-gray-700.font-bold.text-xl.text-center あなただけのブックができました!

    .text-center.my-5.text-green-600
      transition(appear)
        fa-icon.icon(icon='check-circle')

    p.text-gray-700
      | 矢印ボタンを押して各ページをご確認の上
      | そのまま購入される場合は
      b.text-orange-600 「カゴに追加」
      | を、内容を編集される場合は
      b.text-green-700 「編集画面へ」
      | を押してください。

  template(v-slot:footer)
    .buttons.mt-3.p-3.text-center
      button.btn(
        @click='$closeModal(true)'
      ) OK
</template>

<script>
import ModalBase from './modal-base'

export default {
  name: 'IntroductionForPreview',

  components: {
    ModalBase
  }
}
</script>

<style lang="sass" scoped>
.introduction-for-preview
  .icon
    font-size: 5rem

.v-enter-active
  transition: all 1.5s ease
.v-enter
  transform: scale(1.2) rotate(180deg)
  opacity: 0
</style>
