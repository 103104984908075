// プレビュー画面

<template lang="pug">
//- height:100%でなくabsoluteにするのはsafariのバグ対策のため
.edtior-preview.absolute.inset-0.flex.flex-col

  //- header.pt-2.px-3.flex.items-center.justify-between
  //-   .left
  //-     .btn.bg-green-600.py-1.text-white(
  //-       @click='goToEditorMain'
  //-     )
  //-       fa-icon(icon='edit')
  //-       span.ml-1 編集画面へ
  //-   .middle.mx-2.hidden.sm__block
  //-     select.text-lg.h-10.px-2.border-b.rounded-lg.outline-none(
  //-       v-model='currentSpreadIndex'
  //-     )
  //-       option(
  //-         v-for='spread in spreadList',
  //-         :value='spread.index'
  //-       ) {{ spread.name }}
  //-   .right
  //-     .btn.bg-orange-500.py-1.text-white(
  //-       @click='goToCart'
  //-     )
  //-       fa-icon.mr-1(icon='shopping-cart')
  //-       span.ml-1 カゴに追加

  //- main.flex-auto.relative
  spread-viewer-wrapper(
    :is-preview='true'
  )
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import SpreadViewerWrapper from './components/spread-viewer-wrapper'
// 初回プレビューページ表示時のダイアログ
import IntroductionForPreviewModal from '../../modals/introduction-for-preview'

export default {
  name: 'EditorPreview',

  components: {
    SpreadViewerWrapper
  },

  computed: {
    ...mapGetters({
      _currentSpreadIndex: 'editor/currentSpreadIndex',
      spreadList: 'editor/spreadList',
      showPreviewIntroModal: 'editor/showPreviewIntroModal'
    }),
    currentSpreadIndex: {
      get () { return this._currentSpreadIndex },
      set (value) { this.jumpSpread(value) }
    }
  },

  mounted () {
    if (this.showPreviewIntroModal) {
      this.setShowPreviewIntroModal(false)
      // プレビューの説明モーダル表示時は、
      // 編集画面遷移時に編集の説明モーダルも表示する
      this.setShowEditorIntroModal(true)
      this.$showModal(IntroductionForPreviewModal, true)
    }
  },

  methods: {
    ...mapActions({
      setError: 'app/setError',
      saveAndAddToCart: 'editor/saveAndAddToCart',
      jumpSpread: 'editor/jumpSpread',
      setShowPreviewIntroModal: 'editor/setShowPreviewIntroModal',
      setShowEditorIntroModal: 'editor/setShowEditorIntroModal'
    }),
    // カゴに追加
    async goToCart () {
      try {
        // まず保存
        await this.saveAndAddToCart()
        // 次に遷移
        this.$router.push({ name: 'OrderCart' })
      } catch (e) {
        this.setError(e)
      }
    },
    // 編集画面へ
    goToEditorMain () {
      this.$router.push({ name: 'EditorMain' })
    }
  }
}
</script>

<style lang="sass" scoped>

</style>
